import AnalyticsConstants from '@constants/analytics/index.js';
import { useAppSelector } from '@redux/hooks';
import type { InlineRfqSourceContent } from '@typings/sourceContent';
import { Column } from '@xo-union/tk-component-grid';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import type { Decorated } from 'types/vendor';
import RfqModal from '../../../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../pages/Storefront/containers/cta_box/RfqButton';
import FavoritesButton from '../../../../pages/Storefront/containers/vendor_info/components/favorites_button';
import { currentViewDetailAtom } from '../../atoms';
import Styles from './Footer.scss';

const Footer = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const membership = useAppSelector((state) => state.membership);
	const isCurrentViewDetail = useAtomValue(currentViewDetailAtom);

	const sourceContent = (
		isCurrentViewDetail
			? AnalyticsConstants.PHOTO_DETAIL_VIEW
			: AnalyticsConstants.PHOTO_GALLERY
	) as InlineRfqSourceContent;
	const formContext = useInlineRfqForm({
		initiator: sourceContent,
	});

	return (
		<>
			<RfqModal context={formContext} isModalOnModal={true} />
			<div className={Styles.footer} role="presentation">
				<div className={classnames(Styles.buttonsContainer)}>
					<Column xs="6" className={Styles.buttonColumns}>
						<FavoritesButton
							expanded
							membership={membership}
							vendor={vendor}
							className={Styles.favButton}
							sourceContent={sourceContent}
						/>
					</Column>
					<Column xs="6" className={Styles.buttonColumns}>
						<RFQButton
							size="md"
							label="Request Quote"
							handleCTAClick={formContext.openModal}
							htmlId="lightbox-footer-rfq-button"
						/>
					</Column>
				</div>
			</div>
		</>
	);
};

export default Footer;
