import Pill from '@components/shared/PillWithDropdown/Pill';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import {
	type VendorMediaKey,
	currentFilterAtom,
	currentIndexAtom,
	currentViewAtom,
	displayedFilterValuesAtom,
	filterLabels,
	totalItemsAtom,
} from '../../atoms';

import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import Styles from './FilterPills.scss';

const FilterPills = () => {
	const currentFilter = useAtomValue(currentFilterAtom);
	const setCurrentIndex = useSetAtom(currentIndexAtom);
	const setCurrentFilter = useSetAtom(currentFilterAtom);
	const setCurrentView = useSetAtom(currentViewAtom);
	const totalItems = useAtomValue(totalItemsAtom);
	const filterValues = useAtomValue(displayedFilterValuesAtom);
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const categoryCode = vendor.categoryCode;
	const isEmptyStates = useStorefrontEmptyStates();
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);
	const shouldShowVidEmptyState =
		categoryCode === 'VID' && totalItems.videos === 0 && isEmptyStates;

	const handleFilterClick = (filter: VendorMediaKey) => {
		setCurrentFilter(filter);
		setCurrentIndex(0);
		// If only 1 entry, skip overview
		if (totalItems[filter] === 1) {
			setCurrentView('Detail');
		}

		if (shouldShowVidEmptyState && filter === 'videos') {
			setCurrentView('Detail');
		}

		trackUnifiedLightbox(
			AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			AnalyticsConstants.FILTER_CLICKED,
			AnalyticsConstants.PHOTO_GALLERY,
		);
	};

	return (
		<>
			{filterValues.map((filter) => {
				return totalItems[filter] > 0 ? (
					<Pill
						key={filter}
						label={`${filterLabels[filter]} (${totalItems[filter]})`}
						showMapView={false}
						customStyles={
							currentFilter !== filter ? Styles.unselectedFilter : ''
						}
						onClick={() => handleFilterClick(filter)}
						selected={currentFilter === filter}
						customSelectedStyles={
							currentFilter === filter ? Styles.selectedFilter : undefined
						}
					/>
				) : null;
			})}
			{shouldShowVidEmptyState && (
				<Pill
					key="videos"
					label={`${filterLabels.videos} (${totalItems.videos})`}
					showMapView={false}
					customStyles={Styles.unselectedFilter}
					onClick={() => handleFilterClick('videos')}
					selected={currentFilter === 'videos'}
					customSelectedStyles={Styles.selectedFilter}
				/>
			)}
		</>
	);
};

export default FilterPills;
