import { isMediaOfType, isPhotoWide } from '@utils/mediaUtils';
import classnames from 'classnames';
import Styles from '../Carousel.scss';

const getImageStyles = (media: Vendor.Media, isReviewsLightbox = false) => {
	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO')) {
		return getPhotoStyles(media, isReviewsLightbox);
	}
	return { image: '' };
};

const getPhotoStyles = (
	media: Vendor.PhotoMedia,
	isReviewsLightbox: boolean,
) => {
	if (isReviewsLightbox) {
		return {
			image: Styles.lightboxImageReviews,
		};
	}
	if (isPhotoWide(media)) {
		return {
			image: classnames(Styles.lightboxImage, Styles.lightboxImageWide),
		};
	}
	return {
		image: classnames(Styles.lightboxImage, Styles.lightboxImageTall),
	};
};

const getLightboxWrapperStyles = (media: Vendor.Media) => {
	const DEFAULT_MEDIA_WRAPPER_STYLES = Styles.lightboxMediaWrapper;

	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO') && !isPhotoWide(media)) {
		return classnames(DEFAULT_MEDIA_WRAPPER_STYLES, Styles.lightboxImageTall);
	}

	return classnames(DEFAULT_MEDIA_WRAPPER_STYLES);
};

export { getImageStyles, getPhotoStyles, getLightboxWrapperStyles };
