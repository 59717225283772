import NavigableModal, {
	MODAL_TYPES,
	type NavigableModalProps,
	closeNavigableModal,
} from '@components/NavigableModal/NavigableModal';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useAppSelector } from '@redux/hooks';
import type { InlineRfqSourceContent } from '@typings/sourceContent';
import type { Raw } from '@typings/vendor';
import { type ClassesRecord, compose } from '@xo-union/react-css-modules';
import type { FullscreenModalClasses } from '@xo-union/tk-component-modals';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useContext, type FC } from 'react';
import { afterNextPaint } from '../../../helpers/after-next-paint';
import RecommendedContext from '../../contexts/RecommendedContext/RecommendedContext';
import RfqModal from '../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import VrmModals from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/VrmModals/VrmModals';
import Styles from './Lightbox.scss';
import {
	type EntryPoint,
	closeUnifiedLightboxAtom,
	currentViewDetailAtom,
} from './atoms';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import useTrackUnifiedLightbox from './hooks/use-track-unified-lightbox';

export interface UnifiedLightboxModalProps
	extends Omit<NavigableModalProps, 'hash'> {
	entryPoint: EntryPoint;
	closeCallback: () => void;
}

const modalClasses = compose<
	FullscreenModalClasses,
	ClassesRecord<FullscreenModalClasses>
>({
	content: Styles.content,
	header: Styles.header,
});

const UnifiedLightboxModal: FC<UnifiedLightboxModalProps> = ({
	isModalOpen,
	closeCallback,
}) => {
	const hash = 'unified-lightbox-modal';
	const closeUnifiedLightbox = useSetAtom(closeUnifiedLightboxAtom);
	const isCurrentViewDetail = useAtomValue(currentViewDetailAtom);

	const handleCloseModal = useCallback(() => {
		afterNextPaint(() => {
			closeUnifiedLightbox();
			trackUnifiedLightbox(
				AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				AnalyticsConstants.DISMISS,
				isCurrentViewDetail
					? AnalyticsConstants.PHOTO_DETAIL_VIEW
					: AnalyticsConstants.PHOTO_GALLERY,
			);
			closeNavigableModal(closeCallback, hash);
		});
	}, [closeUnifiedLightbox, isCurrentViewDetail, closeCallback]);

	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);

	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const formContext = useInlineRfqForm({
		initiator: (isCurrentViewDetail
			? AnalyticsConstants.PHOTO_DETAIL_VIEW
			: AnalyticsConstants.PHOTO_GALLERY) as InlineRfqSourceContent,
	});

	const {
		modal: vrmModal,
		onClose,
		recommendedVendors,
		setModal,
	} = useContext(RecommendedContext);
	const vendorRaw = useAppSelector((state) => state.vendor.vendorRaw as Raw);

	return (
		<NavigableModal
			hash={hash}
			isModalOpen={isModalOpen}
			closeCallback={handleCloseModal}
			type={MODAL_TYPES.FULL_SCREEN}
			fullscreenModalClasses={modalClasses}
		>
			<Header
				closeModal={handleCloseModal}
				openRfqModal={formContext.openModal}
			/>
			<Body />
			<Footer />
			<RfqModal context={formContext} />
			{vrmModal && (
				<VrmModals
					initiator="photo gallery"
					modal={vrmModal}
					onClose={onClose}
					// @ts-expect-error: 'Similar[]' is not assignable to type 'FESharedProfile[]'
					recommendedVendors={recommendedVendors}
					rfqType="inline"
					setModal={setModal}
					vendorRaw={vendorRaw}
				/>
			)}
		</NavigableModal>
	);
};

export default UnifiedLightboxModal;
