import { BaseEmptyState } from '@components/unified-lightbox/components/EmptyState';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useRef } from 'react';
import type { Decorated, Media, PhotoMedia } from 'types/vendor';
import {
	type VendorMediaKey,
	currentFilterAtom,
	currentIndexAtom,
	currentViewAtom,
	currentViewDetailAtom,
	filteredMediaAtom,
	setCurrentReviewAtom,
	vendorMediaAtom,
} from '../../atoms';
import Carousel from '../Carousel/Carousel';
import { MediaContainer } from '../MediaContainer/MediaContainer';
import ReviewDetails from '../ReviewDetails/ReviewDetails';
import Styles from './Body.scss';

interface BodyOverviewProps {
	currentIndex: number;
	filteredMedia: Media[];
	filter: VendorMediaKey;
	handleMediaClick: (index: number) => void;
	trackUnifiedLightbox: ReturnType<typeof useTrackUnifiedLightbox>;
}

const BodyOverview = ({
	currentIndex = 0,
	filteredMedia,
	filter = 'all',
	handleMediaClick,
	trackUnifiedLightbox,
}: BodyOverviewProps) => {
	const openedFromMediaItemRef = useRef<HTMLDivElement>(null);
	const isEmptyState = useStorefrontEmptyStates();
	const vendorMediaPhotos = useAtomValue(vendorMediaAtom).photos;
	const vendorMediaVideos = useAtomValue(vendorMediaAtom).videos;

	useEffect(() => {
		trackUnifiedLightbox(
			AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			AnalyticsConstants.OVERVIEW_VIEWED,
			AnalyticsConstants.PHOTO_GALLERY,
		);
	}, [trackUnifiedLightbox]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Dependencies are correct
	useEffect(() => {
		// scroll to item when opening modal or scroll to top when changing filter (currentIndex is set to 0)
		openedFromMediaItemRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}, [openedFromMediaItemRef.current, filter]);

	const shouldShowEmptyState =
		isEmptyState && filter === 'all' && vendorMediaPhotos.length < 20;

	const shouldShowVidEmptyState =
		isEmptyState && filter === 'videos' && vendorMediaVideos.length === 0;

	const header = shouldShowVidEmptyState
		? "This vendor hasn't uploaded any videos yet."
		: "You've seen it all!";

	return (
		<div className={Styles.bodyOverview}>
			<div className={`${Styles.mediaGrid} ${Styles[`filter-${filter}`]}`}>
				{filteredMedia.map((mediaItem, index) => {
					let orientation = 'landscape';
					if (
						mediaItem.mediaType === 'PHOTO' &&
						(mediaItem as PhotoMedia).width <= (mediaItem as PhotoMedia).height
					) {
						orientation = 'portrait';
					}
					return (
						<div
							ref={
								mediaItem.id === filteredMedia[currentIndex].id
									? openedFromMediaItemRef
									: null
							}
							key={mediaItem.id}
							className={`${Styles.mediaGridItem} ${Styles[orientation]}`}
							onClick={() => handleMediaClick(index)}
							onKeyDown={() => handleMediaClick(index)}
						>
							<MediaContainer media={mediaItem} />
						</div>
					);
				})}
			</div>
			{shouldShowEmptyState ? <BaseEmptyState header={header} /> : null}
		</div>
	);
};

const BodyDetail = () => {
	const isReview = useAtomValue(currentFilterAtom) === 'reviews';
	const isMobile = useAppSelector((state) => selectIsMobile(state));

	return (
		<div className={Styles.bodyDetail}>
			<div className={Styles.mediaDetail}>
				<Carousel />
			</div>
			{!isMobile && isReview && <ReviewDetails />}
		</div>
	);
};

const Body = () => {
	const isCurrentViewDetail = useAtomValue(currentViewDetailAtom);
	const currentIndex = useAtomValue(currentIndexAtom);
	const filter = useAtomValue(currentFilterAtom);
	const isReview = useAtomValue(currentFilterAtom) === 'reviews';
	const isEmptyState = useStorefrontEmptyStates();
	const vendorMediaVideos = useAtomValue(vendorMediaAtom).videos;
	const shouldShowVidEmptyState =
		isEmptyState && filter === 'videos' && vendorMediaVideos.length === 0;
	const filteredMedia = useAtomValue(filteredMediaAtom);

	const setCurrentView = useSetAtom(currentViewAtom);
	const setCurrentIndex = useSetAtom(currentIndexAtom);
	const setCurrentReview = useSetAtom(setCurrentReviewAtom);

	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const handleMediaClick = (index: number) => {
		if (isReview) setCurrentReview(filteredMedia[index].id);
		setCurrentIndex(index);
		setCurrentView('Detail');

		trackUnifiedLightbox(
			AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			AnalyticsConstants.OVERVIEW_PHOTO_CLICKED,
			AnalyticsConstants.PHOTO_GALLERY,
		);
	};

	return (
		<>
			{(isCurrentViewDetail && filteredMedia[currentIndex]) ||
			shouldShowVidEmptyState ? (
				<BodyDetail />
			) : (
				<BodyOverview
					currentIndex={currentIndex}
					filteredMedia={filteredMedia}
					filter={filter}
					handleMediaClick={handleMediaClick}
					trackUnifiedLightbox={trackUnifiedLightbox}
				/>
			)}
		</>
	);
};

export default Body;
