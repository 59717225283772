import { graphqlFetch } from '@api/graphql/gql-fetch';
import axios from 'axios';
import queryString from 'query-string';
import type {
	GoogleReviewsResponse,
	ReviewAiSummaryResponse,
	ReviewsResponse,
} from 'types/api/reviews';
import type { ReviewFormData } from 'types/reviews';
import {
	googleReviewsUrl,
	reviewAiSummaryUrl,
	reviewsApiKey,
	reviewsApiUrl,
} from '../../settings';
import googleReviewsDataQuery from '../graphql/googleReviews';
import {
	REVIEWS_FOR_PAGE_1,
	REVIEWS_PER_PAGE,
	REVIEW_AI_SUMMARY_MINIMUM_AVERAGE_RATING,
	pageAfterFirst,
} from './constants';

export { REVIEWS_FOR_PAGE_1, REVIEWS_PER_PAGE, pageAfterFirst };
export type ReviewsSort =
	| 'selectiontype:asc|createddate:desc'
	| 'createddate:desc';

const config = {
	headers: { Authorization: reviewsApiKey },
};

export function reviewsForVendor(
	vendorId: string,
	sort: ReviewsSort,
	page: number,
): Promise<ReviewsResponse> {
	return axios.get(`${reviewsApiUrl}/storefront/${vendorId}`, {
		params: {
			size: page === 1 ? REVIEWS_FOR_PAGE_1 : REVIEWS_PER_PAGE,
			sort,
			page,
		},
		...config,
	});
}

export async function reviewAiSummaryForVendor(
	vendorId: string,
): Promise<ReviewAiSummaryResponse> {
	const rawResponse = await axios.post(
		reviewAiSummaryUrl,
		{
			country_code: 'TK',
			vendor_id: vendorId,
			avg_rating_threshold: REVIEW_AI_SUMMARY_MINIMUM_AVERAGE_RATING,
		},
		{
			...config,
			timeout: 3000,
		},
	);

	const reviewSummary = rawResponse.data?.response?.review_summary;

	return {
		data: {
			review_summary: reviewSummary,
		},
	};
}

export const googleReviewsForVendor = async (
	vendorId: string,
): Promise<GoogleReviewsResponse> => {
	const data = {
		url: googleReviewsUrl,
		query: googleReviewsDataQuery,
		variables: {
			storefrontId: vendorId,
		},
		headers: new Headers({ 'x-tenant-id': 'tk-us' }),
	};

	const googleReviewsResponse = await graphqlFetch(data);

	return googleReviewsResponse?.data?.getGooglePlace;
};

export interface GetReviewsForStorefrontQueryParams {
	content: string | undefined;
	page: number;
	stars: number[] | undefined;
	sort:
		| 'selectiontype:asc|createddate:desc'
		| 'createddate:desc'
		| 'createddate:asc'
		| 'rating:desc'
		| 'rating:asc';
}

export function getReviewsForMember(memberId: string) {
	const headers = new Headers();
	headers.append('Authorization', reviewsApiKey);
	const query = queryString.stringify({
		size: REVIEWS_PER_PAGE,
		sort: 'createddate:desc',
		page: 1,
	});
	return fetch(`${reviewsApiUrl}/member/${memberId}?${query}`, { headers });
}

export function getReviewsForStorefront(
	vendorId: string,
	queryParams: GetReviewsForStorefrontQueryParams,
	signal?: AbortSignal,
) {
	const size = queryParams.page === 1 ? REVIEWS_FOR_PAGE_1 : REVIEWS_PER_PAGE;
	const query = queryString.stringify({ ...queryParams, size });
	return fetch(`${reviewsApiUrl}/storefront/${vendorId}?${query}`, { signal });
}

export function getReviewsForGallery(vendorId: string, page: number) {
	const size = page === 1 ? REVIEWS_FOR_PAGE_1 : REVIEWS_PER_PAGE;
	const query = queryString.stringify({
		page,
		size,
		photos: true,
		sort: 'selectiontype:asc|createddate:desc',
	});
	return fetch(`${reviewsApiUrl}/storefront/${vendorId}?${query}`);
}

export const staticStorefrontReviewData = {
	reviewerRoleId: 'other',
	source: {
		application: 'theknot',
		platform: 'web',
		categoryCode: null,
		marketCode: null,
	},
};

export function postUnauthedStorefrontReview(
	vendorId: string,
	data: ReviewFormData,
) {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	const raw = JSON.stringify({ ...data, ...staticStorefrontReviewData });
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
	};

	return fetch(
		`${reviewsApiUrl}/storefront/${vendorId}/unauthed`,
		requestOptions,
	);
}

export function postAuthedStorefrontReview(
	vendorId: string,
	data: ReviewFormData,
	token: string,
) {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('membership-session-token', token);
	const { email, ...rest } = data;
	const raw = JSON.stringify({ ...rest, ...staticStorefrontReviewData });
	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
	};

	return fetch(`${reviewsApiUrl}/storefront/${vendorId}`, requestOptions);
}

export function updateReview(
	vendorId: string,
	data: ReviewFormData,
	token: string,
	reviewId: string,
) {
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	myHeaders.append('membership-session-token', token);
	const { email, firstName, lastName, ...rest } = data;
	const { reviewerRoleId, source } = staticStorefrontReviewData;
	const raw = JSON.stringify({
		id: reviewId,
		...rest,
		source,
		reviewer: { firstName, lastName, role: reviewerRoleId },
	});
	const requestOptions = {
		method: 'PUT',
		headers: myHeaders,
		body: raw,
	};

	return fetch(`${reviewsApiUrl}/storefront/${vendorId}`, requestOptions);
}
