import {
	currentViewDetailAtom,
	isUnifiedLightboxOpenAtom,
} from '@components/unified-lightbox/atoms';
import TourIcon from '@components/unified-lightbox/components/MediaContainer/components/TourIcon/TourIcon';
import type { MediaType } from '@typings/vendor/properties';
import { useAtomValue } from 'jotai';
import React from 'react';
import PlayIcon from '../PlayIcon';
import {
	getTourPlaceholderIconStyles,
	getVideoPlaceholderIconStyles,
} from './utils';

export interface PlaceholderIconProps {
	mediaType: MediaType;
}

const PlaceholderIcon = (props: PlaceholderIconProps) => {
	const { mediaType } = props;
	const isUnifiedLightboxOpen = useAtomValue(isUnifiedLightboxOpenAtom);
	const isLightboxCurrentViewDetail = useAtomValue(currentViewDetailAtom);

	switch (mediaType) {
		case 'VIDEO':
		case 'VIDEO_UPLOAD':
			return (
				<div
					className={getVideoPlaceholderIconStyles(isLightboxCurrentViewDetail)}
				>
					<PlayIcon />
				</div>
			);
		case '360TOUR':
			return (
				<div
					className={getTourPlaceholderIconStyles(
						isUnifiedLightboxOpen,
						isLightboxCurrentViewDetail,
					)}
				>
					<TourIcon />
				</div>
			);
	}

	return null;
};

export default PlaceholderIcon;
