import AnalyticsConstants from '@constants/analytics/index.js';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import { IconButton } from '@xo-union/tk-component-icons';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import type { Decorated } from 'types/vendor';
import RFQButton from '../../../../pages/Storefront/containers/cta_box/RfqButton';
import FavoritesButton from '../../../../pages/Storefront/containers/vendor_info/components/favorites_button';
import {
	currentFilterTotalItemsAtom,
	currentIndexAtom,
	currentViewAtom,
	currentViewDetailAtom,
	headerTitleAtom,
	setIsPlayingAtom,
} from '../../atoms';
import FilterPills from '../FilterPills/FilterPills';
import Styles from './Header.scss';

export interface HeaderProps {
	closeModal: () => void;
	openRfqModal: () => void;
}

const Title = ({
	isCurrentViewDetail,
	title,
}: { isCurrentViewDetail: boolean; title: string }) => {
	return (
		<Body1 bold className={isCurrentViewDetail ? Styles.headerTitle : ''}>
			{title}
		</Body1>
	);
};

const OverviewHeaderLeft = ({
	isCurrentViewDetail,
	title,
}: { isCurrentViewDetail: boolean; title: string }) => {
	return (
		<>
			<div className={Styles.titleContainer}>
				<Title isCurrentViewDetail={isCurrentViewDetail} title={title} />
			</div>
			<div className={Styles.filterContainer}>
				<FilterPills />
			</div>
		</>
	);
};

const LightboxHeaderLeft = ({
	isCurrentViewDetail,
	title,
	currentIndex,
	currentFilterTotalItems,
}: {
	isCurrentViewDetail: boolean;
	title: string;
	currentIndex: number;
	currentFilterTotalItems: number;
}) => {
	const setCurrentView = useSetAtom(currentViewAtom);
	const setIsPlaying = useSetAtom(setIsPlayingAtom);
	const isEmptyStates = useStorefrontEmptyStates();

	const currentIndexDisplay = useMemo(() => {
		if (!isEmptyStates) return currentIndex + 1;
		return currentIndex + 1 > currentFilterTotalItems
			? currentFilterTotalItems
			: currentIndex + 1;
	}, [isEmptyStates, currentIndex, currentFilterTotalItems]);

	const shouldShowItemsCount = currentIndexDisplay !== 0;

	return (
		<>
			<div className={Styles.backButton}>
				<IconButton
					name="back"
					size="md"
					block
					aria-label="back"
					onClick={() => {
						setIsPlaying('');
						setCurrentView('Overview');
					}}
				/>
			</div>
			<div className={Styles.titleContainer}>
				<Title isCurrentViewDetail={isCurrentViewDetail} title={title} />
				{shouldShowItemsCount && (
					<Body2 className={Styles.mediaIndexColor}>
						{currentIndexDisplay}/{currentFilterTotalItems}
					</Body2>
				)}
			</div>
		</>
	);
};

const Header = ({ closeModal, openRfqModal }: HeaderProps) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const membership = useAppSelector((state) => state.membership);
	const filled = useAppSelector((state) => state.favorites.filled);
	const headerTitle = useAtomValue(headerTitleAtom);
	const isCurrentViewDetail = useAtomValue(currentViewDetailAtom);
	const currentIndex = useAtomValue(currentIndexAtom);
	const currentFilterTotalItems = useAtomValue(currentFilterTotalItemsAtom);

	return (
		<div className={Styles.header}>
			<div className={Styles.headerRow}>
				{!isCurrentViewDetail ? (
					<OverviewHeaderLeft
						isCurrentViewDetail={isCurrentViewDetail}
						title={headerTitle}
					/>
				) : (
					<LightboxHeaderLeft
						isCurrentViewDetail={isCurrentViewDetail}
						title={headerTitle}
						currentIndex={currentIndex}
						currentFilterTotalItems={currentFilterTotalItems}
					/>
				)}
				<div className={Styles.headerRight}>
					<div className={Styles.headerButtons}>
						<span
							className={classNames({
								[Styles.favButton]: true,
								[Styles.emptyHeart]: !filled,
							})}
						>
							<FavoritesButton
								expanded
								membership={membership}
								vendor={vendor}
								sourceContent={
									isCurrentViewDetail
										? AnalyticsConstants.PHOTO_DETAIL_VIEW
										: AnalyticsConstants.PHOTO_GALLERY
								}
							/>
						</span>
						<RFQButton
							size="md"
							handleCTAClick={openRfqModal}
							htmlId="lightbox-header-rfq-button"
						/>
					</div>
					<div className={Styles.closeBtnContainer}>
						<IconButton
							name="close"
							size="md"
							block
							aria-label="close"
							className={Styles.closeBtn}
							onClick={closeModal}
						/>
					</div>
				</div>
			</div>
			{!isCurrentViewDetail && (
				<div className={Styles.mobileFilterRow}>
					<FilterPills />
				</div>
			)}
		</div>
	);
};

export default Header;
