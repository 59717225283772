const query = `
  query($storefrontId: String!) {
    getGooglePlace(storefrontId: $storefrontId) {
      rating
      totalReviewCount
      externalUrl
      reviews {
        id
      	authorName
      	publicProfilePicture
        publishedAt
        rating
        reviewText
    	}
    }
  }
`;

export default query;
